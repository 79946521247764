import React from 'react'
import StakeAndEarn from '../components/StakeAndEarn'
import page from '~utils/page'
import { NETWORK } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(StakeAndEarn, {
    highlight: NETWORK,
    streamrClient: true,
    headComponent: (
        <Head
            mediaDescription="Stake and Earn"
            // eslint-disable-next-line max-len
            description="Streamr is a decentralised network powered by people the world over. By running a Streamr node and using your latent compute power - you help secure the network while enjoying high returns on your staked $DATA."
        />
    ),
})
