import React from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import Container from '~shared/Container'
import PrestyledLayout from '~shared/Layout'
import { SectionTitle } from '~shared/PersonaPage'
import PersonaPageFaq from '~shared/PersonaPage/PersonaPageFaq'
import PersonaPageHero from '~shared/PersonaPage/PersonaPageHero'
import PersonaPageSection from '~shared/PersonaPage/PersonaPageSection'
import Spacer from '~shared/Spacer'
import Tatum from '~shared/Tatum'
import Topic from '~shared/Topic'
import { StakeAndEarnQAs } from '~utils/faqs'
import Delegation from './Delegation'
import Steps from './Steps'

const Layout = styled(PrestyledLayout)`
    padding-bottom: 0 !important;
`

const UnstyledStakeAndEarn = (props) => (
    <Layout {...props}>
        <PersonaPageSection
            gradient="180deg, #dce1fa 0%, #dbeaf6 36.31%, white 100%"
            mobileGradient="180deg, #dce1fa 0%, #dbeaf6 36.31%, rgba(219, 241, 242, 0) 100%"
        >
            <PersonaPageHero
                persona="nodeRunner"
                title="Stake and Earn"
                desc={
                    <p>
                        Streamr is a decentralised network powered by people the world over.
                        By&nbsp;operating a Streamr node you help to secure the network with
                        your&nbsp;latent&nbsp;bandwidth.
                    </p>
                }
            />
            <div id="before-you-start" />
            <Steps />
        </PersonaPageSection>
        <div id="delegation-with-tatum" />
        <Spacer pad head="5.75rem,0,">
            <DelegationWrap>
                <Spacer pad head="4.125rem,10rem,13.75rem" tail="4.125rem,7.5rem,10rem">
                    <SectionTitle $alwaysCentered $marginless>
                        <a href="#delegation-with-tatum">Delegation with Streamr 1.0</a>
                    </SectionTitle>
                    <Delegation />
                    <Container>
                        <SubtitleText>
                            Delegators can provide liquidity in DATA tokens to Operators so that
                            they can stake on more Sponsorships, and earn more rewards. In return,
                            the Operators share their earnings with the Delegators.
                        </SubtitleText>
                    </Container>
                </Spacer>
            </DelegationWrap>
        </Spacer>
        <PersonaPageFaq title={<>Stake &amp; Earn FAQ</>}>
            <StakeAndEarnQAs />
        </PersonaPageFaq>
        <div id="tatum" />
        <Topic pad head="7.5rem,9rem,19rem">
            <Tatum />
        </Topic>
    </Layout>
)

const DelegationWrap = styled.div`
    background: white;
`

const SubtitleText = styled.div`
    text-align: center;
    margin: 0 auto;
    line-height: 28px;
    margin-top: 25px;

    @media ${MqTablet} {
        max-width: 600px;
        line-height: 30px;
    }

    @media ${MqDesktop} {
        font-size: 18px;
    }
`

const StakeAndEarn = styled(UnstyledStakeAndEarn)`
    color: #0c009a;
`

export default StakeAndEarn
