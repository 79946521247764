import React from 'react'
import { ButtonSize, PrimaryButton } from 'streamr-ui'
import { MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import ButtonWrapper from '~shared/ButtonWrapper'
import Container from '~shared/Container'
import Faq from '~shared/Faq'
import { SectionTitle } from '~shared/PersonaPage'
import Spacer from '~shared/Spacer'
import urls from '~utils/urls'

export default function PersonaPageFaq({ title = <>Untitled FAQ</>, children }) {
    return (
        <>
            <div id="faq" />
            <Root pad head="6rem,6.25rem,9rem">
                <Container>
                    <SectionTitle $appear $alwaysCentered>
                        <a href="#faq">{title}</a>
                    </SectionTitle>
                    <Faq>{children}</Faq>
                    <ButtonWrapper>
                        <PrimaryButton
                            tag="a"
                            href={urls.faq}
                            size={ButtonSize.Moose}
                            paint={css`
                                --radius: 8px;
                            `}
                        >
                            More answers on FAQ
                        </PrimaryButton>
                    </ButtonWrapper>
                </Container>
            </Root>
        </>
    )
}

const Root = styled(Spacer)`
    ${ButtonWrapper} {
        margin: 60px auto 0 auto;
        display: flex;
        justify-content: center;
    }

    @media ${MqTablet} {
        ${ButtonWrapper} {
            margin: 120px auto 0 auto;
        }
    }
`
