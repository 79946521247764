import React from 'react'
import styled from 'styled-components'
import { Semibold, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import { PrimaryButton, ButtonSize, ButtonPaint } from 'streamr-ui'
import docs from './assets/docs.png'
import docs2x from './assets/docs@2x.png'
import faq from './assets/faq.png'
import faq2x from './assets/faq@2x.png'
import discord from './assets/discord.png'
import discord2x from './assets/discord@2x.png'
import data from './assets/data.png'
import data2x from './assets/data@2x.png'
import apy from './assets/apy.png'
import apy2x from './assets/apy@2x.png'
import urls from '~utils/urls'

const assets = {
    docs: [docs, docs2x],
    faq: [faq, faq2x],
    discord: [discord, discord2x],
    data: [data, data2x],
    apy: [apy, apy2x],
}

const UnstyledIcon = ({ name, ...props }) => {
    const [icon, icon2x] = assets[name]

    return (
        <div {...props}>
            <img className={name} src={icon} srcSet={`${icon2x} 2x`} alt="" />
        </div>
    )
}

const Icon = styled(UnstyledIcon)`
    img {
        display: block;
        margin: 0 auto;
        max-height: ${({ large }) => (large ? '105px' : '75px')};
        margin-left: ${({ large }) => (large ? 'auto' : '25px')};
        margin-top: ${({ large }) => (large ? '50px' : '25px')};
        margin-bottom: ${({ large }) => (large ? '25px' : '0px')};
    }

    .faq {
        max-width: 84px;
    }

    @media ${MqTablet} {
        img {
            max-height: ${({ large }) => (large ? '141px' : '80px')};
            margin: 0 auto;
            margin-top: 40px;
            max-width: 300px;
        }

        .faq {
            max-width: none;
        }
    }

    @media ${MqDesktop} {
        img {
            max-height: 100%;
            margin-top: 70px;
            max-width: none;
        }
    }
`

const Title = styled.div`
    font-weight: ${Semibold};
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 1rem;

    @media ${MqTablet} {
        font-size: 1.75rem;
        margin-bottom: 1.25rem;
    }
`

const Description = styled.div`
    font-size: 1rem;
    line-height: 1.625rem;
`

const Info = styled.div``

const Container = styled.div``

const UnstyledStep = ({
    title,
    description,
    icon,
    url,
    className,
    button,
    large = false,
    ...props
}) => (
    <Container {...props} className={className} $appear>
        {large && <Title>{title}</Title>}
        {!!icon && !!assets[icon] && <Icon large={large} name={icon} />}
        <Info>
            <Description>{description}</Description>
            {!large && (
                <PrimaryButton
                    size={ButtonSize.Moose}
                    paint={ButtonPaint.Secondary}
                    tag="a"
                    href={url}
                    blank
                >
                    {button}
                </PrimaryButton>
            )}
        </Info>
    </Container>
)

const Step = styled(UnstyledStep)`
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: ${({ large = false }) => (large ? 'column' : 'row')};
    height: auto;
    width: calc(100vw - 36px);
    max-width: 344px;
    margin-bottom: ${({ large = false }) => (large ? '16px' : '8px')};
    margin-top: ${({ large = false }) => (large ? '16px' : '0px')};
    box-shadow: 0px 6.30704px 50px -6.30704px rgba(0, 0, 0, 0.1);

    ${Info} {
        width: auto;
        color: #0c009a;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: auto;
        padding: ${({ large = false }) => (large ? '24px' : '24px 24px 32px 30px')};

        ${PrimaryButton} {
            width: auto;
            max-width: 100px;
            color: #0324ff;
            background: #f5f5f5;
            margin-top: 16px;
            border-radius: 8px;
            height: 32px;
            font-size: 14px;
        }
    }

    ${Title} {
        margin-top: 32px;
        margin-left: 24px;
    }

    @media ${MqTablet} {
        width: ${({ large = false }) => (large ? '344px' : '226px')};
        height: ${({ large = false }) => (large ? '526px' : '309px')};
        margin-top: ${({ large = false }) => (large ? '88px' : '0px')};
        flex-direction: column;
        margin-bottom: 0px;
        max-width: none;

        ${Info} {
            justify-content: center;
            padding: ${({ large = false }) =>
                large ? '0px 48px 36px 48px' : '0px 16px 24px 16px'};

            ${Description} {
                display: block;
                text-align: ${({ large = false }) => (large ? 'left' : 'center')};
                font-size: 16px;
                line-height: 26px;
            }

            ${PrimaryButton} {
                max-width: none;
                height: 32px;
                width: 100%;
            }
        }

        ${Title} {
            margin-top: 48px;
            margin-left: 40px;
        }
    }

    @media ${MqDesktop} {
        box-shadow: 0px 7px 25px -7px rgba(0, 0, 0, 0.04);
        height: auto;
        flex-direction: column;
        width: ${({ large = false }) => (large ? '488px' : '320px')};
        height: ${({ large = false }) => (large ? '609px' : '410px')};
        margin-top: ${({ large = false }) => (large ? '97px' : '0px')};

        ${Info} {
            padding: ${({ large = false }) =>
                large ? '0px 48px 36px 48px' : '0px 24px 32px 24px'};
            max-width: none;
            width: auto;
            justify-content: flex-start;

            ${PrimaryButton} {
                margin-top: 24px;
                height: 48px;
                font-size: 16px;
            }
        }

        ${Title} {
            margin-top: 48px;
            margin-left: 48px;
        }

        ${Description} {
            font-size: ${({ large = false }) => (large ? '18px' : '16px')};
            line-height: ${({ large = false }) => (large ? '30px' : '26px')};
        }
    }
`

const StepContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    flex-direction: column;

    @media ${MqTablet} {
        flex-direction: row;
        max-width: 704px;
    }

    @media ${MqDesktop} {
        max-width: 998px;
    }
`

const TitleContainer = styled.div`
    text-align: center;
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 20px;

    @media ${MqTablet} {
        margin-bottom: 45px;
    }

    @media ${MqDesktop} {
        margin-bottom: 55px;
    }
`

const UnstyledSteps = () => (
    <div>
        <TitleContainer>
            <Title>Node set up</Title>
            <Description>
                This step takes between 20mins and 1 hour depending on your
                technical&nbsp;capability.
            </Description>
        </TitleContainer>
        <StepContainer>
            <Step
                icon="docs"
                button="Visit"
                description={<>For full technical details visit the&nbsp;docs.</>}
                url={urls.docs.becomeAnOperator}
            />
            <Step
                icon="faq"
                button="Take a look"
                description={<>For troubleshooting issues - please take a look at the&nbsp;FAQs.</>}
                url={urls.faq}
            />
            <Step
                icon="discord"
                button="Discover"
                url={urls.community.discord}
                description={<>Need more help? Jump into our Discord Node-help&nbsp;channel.</>}
            />
        </StepContainer>
    </div>
)

const Steps = styled(UnstyledSteps)`
    display: flex;
    justify-content: center;
    text-align: center;
`

export default Steps
